<template>
  <div class="page-layout__inner">
    <div :class="'page-layout__filter' + (filterSettings.visible ? '' : ' w-auto')">
      <section :class="'section  section--shadow-0  section--p-0' + (filterSettings.visible ? ' h-100' : '')">
        <button @click="filterSettings.visible = true" :class="'section__filter-btn' + (filterSettings.visible ? ' section__filter-btn--hidden' : '')">
          <svg class="section__filter-icon">
            <use xlink:href="#icon__filter"></use>
          </svg>
        </button>
        <div :class="'section__filter' + (filterSettings.visible ? '' : ' section__filter--hidden')">
          <div class="section__header">
            <h2 class="section__title">
              <svg class="section__filter-icon">
                <use xlink:href="#icon__filter"></use>
              </svg>
              {{ getTranslation('filter') }}
            </h2>

            <div class="section__controls">
              <div class="section__controls-group">
                <a @click="filterSettings.visible = false" class="btn btn-link " href="javascript:void(0)">{{ getTranslation('hide') }}</a>
                <a class="btn  btn-link" href="javascript:void(0)" @click="resetFilter">{{ getTranslation('reset') }}</a>
              </div>
            </div>
          </div>
          <div class="section__body">
            <div class="form-group">
              <form method="get" @submit.prevent="fetchItems(1)">
                <label class="form__label form__label--block">{{ getTranslation('search') }}</label>
                <div class="field-advanced">
                  <div class="field-advanced__inner">
                    <div class="field-advanced__field-wrap">
                      <input v-model="filterForm.query" class="field  field--full-width  field--bg-grey  js-borderOnFocus" type="text" :placeholder="getTranslation('search')">
                    </div>

                    <div class="field-advanced__right">
                      <button class="btn btn-primary">
                        <svg class="btn__icon">
                          <use xlink:href="#icon__search"/>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div class="form-group">
              <label class="form-check-label form__label text-capitalize">
                {{ getTranslation('gender') }}
              </label>
              <div class="form-check mt-2">
                <input class="form-check-input" type="checkbox" v-model="filterForm.gender" :value="1" id="defaultCheck1">
                <label class="form-check-label form__label" for="defaultCheck1">
                  {{ getTranslation('gender_male') }}
                </label>
              </div>
              <div class="form-check mt-2">
                <input class="form-check-input" type="checkbox" :value="2" v-model="filterForm.gender" id="clientNotPaid">
                <label class="form-check-label form__label" for="clientNotPaid">
                  {{ getTranslation('gender_female') }}
                </label>
              </div>
            </div>

            <div class="form-group">
              <label class="form-check-label form__label text-capitalize mb-5">
                {{ getTranslation('age') }}
              </label>
              <range-slider :min="0"
                            :max="100"
                            :interval="1"
                            v-model="filterForm.age"
                            :dot-options="[{tooltip: 'always'}, {tooltip: 'always'}]"
                            tooltip-formatter="{value}"
                            @drag-end="() => {filterForm.page=1;fetchItems()}"
              ></range-slider>
            </div>

          </div>
        </div>
      </section>
    </div>
    <div class="page-layout__content">
      <p class="text-muted mb-3">{{ getTranslation('resultsFoundWithQuantity', pagination.total) }}</p>
      <div class="table-responsive">
        <section class="section">
          <div class="section__body table-responsive">
            <table class="table  table--noborder table-hover">
              <thead class="table__head">
              <tr class="table__row">
                <th class="table__cell  table__hcell" style="width: 0.3%;">{{ getTranslation('avatar') }}</th>
                <th class="table__cell  table__hcell" style="width: 2%;">{{ getTranslation('fio') }}</th>
                <th class="table__cell  table__hcell" style="width: 2%;">{{ getTranslation('gender') }}</th>
                <th class="table__cell  table__hcell" style="width: 0.2%;"></th>
              </tr>
              </thead>
              <tbody class="table__body">
              <tr v-for="item in items" v-bind:key="item.id" class="table__row">
                <td class="table__cell">
                  <div class="c-avatar">
                    <img :src="$config.noProfilePhotoUrl" alt="фото" class="c-avatar-img">
                    <!--                    <span class="c-avatar-status bg-success"></span>-->
                  </div>
                </td>

                <td class="table__cell">
                  <div>
                    <router-link :to="{name:'client.get',params:{id:item.id}}">{{ item.fullName }}</router-link>
                  </div>
                  <div class="small text-muted">
                    {{ item.bdate }} ({{ getTranslation('yearsOld', item.age) }})
                  </div>
                </td>
                <td class="table__cell">
                  {{ getTranslation(item.gender_text) }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </section>
      </div>
      <CPagination
          v-if="pagination.last_page > 1"
          class="mt-5"
          :active-page.sync="pagination.current_page"
          :pages="pagination.last_page"
          :limit="10"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "Index",
  data() {
    return {
      pagination: {
        total: 0,
        last_page: '',
        current_page: ''
      },
      filterForm: {
        page: this.$route.query.page || 1,
        gender: [],
        age: [ 0, 100 ],
      },
      filterSettings: {
        visible: true
      },
      items: []
    }
  },
  created() {
    this.fetchItems();

    this.setHeadings({
      title: this.getTranslation('clients'),
      breadcrumbs: [
        {
          to: { name: 'client.getList' },
          text: this.getTranslation('clients')
        }
      ],
    });
  },
  watch: {
    'pagination.current_page'(val) {
      this.fetchItems(val);
    },
    'filterForm.gender'() {
      this.fetchItems();
    }

  },
  methods: {
    fetchItems(page = null) {

      if ( page )
        this.filterForm.page = page;

      this.axios.get(this.$urls.client.getList.url, { params: this.cleanObject({ ...this.filterForm }) })
          .then(response => {
            this.items = response.data.data.items;
            this.pagination = response.data.data.pagination;
          }).catch(() => {
      });
    },
    resetFilter() {
    }

  }
}
</script>

<style scoped>

</style>